import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../../utils/HeaderTitle/HeaderTitle';
import NormalTabs from '../../../utils/ScrollableTab/NormalTabs';
import { Breadcrumb, BreadcrumbLink } from '../../../utils/Breadcrumbs/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { Status } from './maintenanceAidTab/status';
import Isir from './maintenanceAidTab/isir'
import IsirHistorical from './maintenanceAidTab/isirHistorical'
import Awards from './maintenanceAidTab/awards'
import Documents from './maintenanceAidTab/documents';
import { financialAid_maintenance } from "../../../services/navigation_folder/financialAid";
import More from './maintenanceAidTab/more';
import { useDispatch } from 'react-redux';
import { getSelectedProfiles } from '../../../redux/common/search-data/action';
import { useHistory } from 'react-router';
import CustomLoader from '../../../components/Loader/Loader';
import { ButtonWrapperFilter } from '../../../styles/common-styles';
import AlertsModal from '../../../components/Modal/AlertsModal';
import { Button } from '@material-ui/core';
import { getApi } from '../../../services/apiBase';
import { student_alert } from '../../../services/apiURL';


const MaintenanceAid = () => {

    const { t } = useTranslation(['translation', 'financialAid', 'financialAidHeadcells', 'tableHeadCells']);

    const [selectedSelected, SetSelectedSelected] = useState('')
    const [showTab, setShowTab] = useState(false);
    const [title] = useState('Maintenance');
    const [fyDropDown, setFyDropDown] = useState([]);
    const [fyStatusDropDown, setStatusFyDropDown] = useState([]);
    const [disableFlag, setDisableFlag] = useState(true);
    

    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoading, setIsloading] = useState(true);
    const [popupData, setPopupData] = useState({
        popupHeaderTitle: '',
        alertsData: {},
        open: false,
        studentId: "",
        tableData: []
    });
    const [tableCourseInGroupCell1] = useState([
        { id: 'textTerm', numeric: true, disablePadding: false, label: t('students.textTerm', { ns: 'tableHeadCells' }) },
        { id: 'enrollmentStatus', numeric: true, disablePadding: false, label: t('students.enrollmentStatus', { ns: 'tableHeadCells' }) },
        { id: 'alert', numeric: true, disablePadding: false, label: t('students.alert', { ns: 'tableHeadCells' }) },
        { id: 'studentLevel', numeric: true, disablePadding: false, label: t('students.studentLevel', { ns: 'tableHeadCells' }) },
        { id: 'collegeLevel', numeric: true, disablePadding: false, label: t('students.collegeLevel', { ns: 'tableHeadCells' }) },
        { id: 'classification', numeric: true, disablePadding: false, label: t('students.classification', { ns: 'tableHeadCells' }) },
        { id: 'studentLoad', numeric: true, disablePadding: false, label: t('students.studentLoad', { ns: 'tableHeadCells' }) },
        { id: 'commuter', numeric: true, disablePadding: false, label: t('students.commuter', { ns: 'tableHeadCells' }) },
        { id: 'description', numeric: true, disablePadding: false, label: t('students.description', { ns: 'tableHeadCells' }) },
        { id: 'academicStatus', numeric: true, disablePadding: false, label: t('students.academicStatus', { ns: 'tableHeadCells' }) },
        { id: 'financialAid', numeric: true, disablePadding: false, label: t('students.financialAid', { ns: 'tableHeadCells' }) },
        { id: 'refundType', numeric: true, disablePadding: false, label: t('students.refundType', { ns: 'tableHeadCells' }) },
        { id: 'costType', numeric: true, disablePadding: false, label: t('students.costType', { ns: 'tableHeadCells' }) },
        { id: 'campus', numeric: true, disablePadding: false, label: t('students.campus', { ns: 'tableHeadCells' }) },
        { id: 'registerStatus', numeric: true, disablePadding: false, label: t('students.registerStatus', { ns: 'tableHeadCells' }) },
        { id: 'gpaGrouping', numeric: true, disablePadding: false, label: t('students.gpaGrouping', { ns: 'tableHeadCells' }) },
        { id: 'commentPosition', numeric: true, disablePadding: false, label: t('students.commentPosition', { ns: 'tableHeadCells' }) },
        { id: 'transcriptComment', numeric: true, disablePadding: false, label: t('students.transcriptComment', { ns: 'tableHeadCells' }) },
        { id: 'leaveOfAbsenceDue', numeric: true, disablePadding: false, label: t('students.leaveOfAbsenceDue', { ns: 'tableHeadCells' }) },
        { id: 'vocation', numeric: true, disablePadding: false, label: t('students.vocation', { ns: 'tableHeadCells' }) },
        { id: 'maxAllowedHours', numeric: true, disablePadding: false, label: t('students.maxAllowedHours', { ns: 'tableHeadCells' }) },
        { id: 'chargeInsurance', numeric: true, disablePadding: false, label: t('students.chargeInsurance', { ns: 'tableHeadCells' }) },
        { id: 'statEffectiveDate', numeric: true, disablePadding: false, label: t('students.statEffective', { ns: 'tableHeadCells' }), dateTime: true },
        { id: 'cohortGroup', numeric: true, disablePadding: false, label: t('students.cohortGroup', { ns: 'tableHeadCells' }) },
        { id: 'loadChangeDate', numeric: true, disablePadding: false, label: t('students.loadChangeDate', { ns: 'tableHeadCells' }), dateTime: true },
        { id: 'studentRegistered', numeric: true, disablePadding: false, label: t('students.studentRegistered', { ns: 'tableHeadCells' }) },
        { id: 'insertUserID', numeric: true, disablePadding: false, label: t('students.insertUserID', { ns: 'tableHeadCells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('students.insertTime', { ns: 'tableHeadCells' }), dateTime: true },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('students.updateUserID', { ns: 'tableHeadCells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('students.updateTime', { ns: 'tableHeadCells' }), dateTime: true },
        { id: 'gradeCatalog', numeric: true, disablePadding: false, label: t('students.gradeCatalog', { ns: 'tableHeadCells' }) },
        { id: 'gradecatalogID', numeric: true, disablePadding: false, label: t('students.gradeCatalogID', { ns: 'tableHeadCells' }) },
        { id: 'nonStateReporting', numeric: true, disablePadding: false, label: t('students.nonStateReporting', { ns: 'tableHeadCells' }) },
        { id: 'degreeSeeking', numeric: true, disablePadding: false, label: t('students.degreeSeeking', { ns: 'tableHeadCells' }) },
        { id: 'institutionalSAP', numeric: true, disablePadding: false, label: t('students.institutionalSAP', { ns: 'tableHeadCells' }) },
        { id: 'institutionalSAPID', numeric: true, disablePadding: false, label: t('students.institutionalSAPID', { ns: 'tableHeadCells' }) },
        { id: 'governmentalSAP', numeric: true, disablePadding: false, label: t('students.governmentalSAP', { ns: 'tableHeadCells' }) },
        { id: 'governmentalSAPID', numeric: true, disablePadding: false, label: t('students.governmentalSAPID', { ns: 'tableHeadCells' }) },
        { id: 'lastDateOfAttendance', numeric: true, disablePadding: false, label: t('students.lastDateOfAttendance', { ns: 'tableHeadCells' }) },
        { id: 'studentWorker', numeric: true, disablePadding: false, label: t('students.studentWork', { ns: 'tableHeadCells' }) },
    ])
    const [tableCourseInGroupCell2] = useState([
        { id: 'holdStatus', numeric: true, disablePadding: false, label: t('students.holdStatus', { ns: 'tableHeadCells' }) },
        { id: 'holdDate', numeric: true, disablePadding: false, label: t('students.holdDate', { ns: 'tableHeadCells' }), dateTime: true },
        { id: 'holdCategory', numeric: true, disablePadding: false, label: t('students.holdCategory', { ns: 'tableHeadCells' }) },
        { id: 'holdDescription', numeric: true, disablePadding: false, label: t('students.holdDescription', { ns: 'tableHeadCells' }) },
        { id: 'voidReason', numeric: true, disablePadding: false, label: t('students.voidReason', { ns: 'tableHeadCells' }) },
        { id: 'voidUserID', numeric: true, disablePadding: false, label: t('students.voidUserID', { ns: 'tableHeadCells' }) },
        { id: 'stopStatement', numeric: true, disablePadding: false, label: t('students.stopStatement', { ns: 'tableHeadCells' }) },
        { id: 'stopTranscript', numeric: true, disablePadding: false, label: t('students.stopTranscript', { ns: 'tableHeadCells' }) },
        { id: 'stopGradeCard', numeric: true, disablePadding: false, label: t('students.stopGradeCard', { ns: 'tableHeadCells' }) },
        { id: 'insertUserID', numeric: true, disablePadding: false, label: t('students.insertUserID', { ns: 'tableHeadCells' }) },
        { id: 'insertTime', numeric: true, disablePadding: false, label: t('students.insertTime', { ns: 'tableHeadCells' }), dateTime: true },
        { id: 'updateUserID', numeric: true, disablePadding: false, label: t('students.updateUserID', { ns: 'tableHeadCells' }) },
        { id: 'updateTime', numeric: true, disablePadding: false, label: t('students.updateTime', { ns: 'tableHeadCells' }), dateTime: true },
        { id: 'stopOnLineRegistration', numeric: true, disablePadding: false, label: t('students.stopOnLineRegistration', { ns: 'tableHeadCells' }) },
        { id: 'location', numeric: true, disablePadding: false, label: t('students.location', { ns: 'tableHeadCells' }) },
        { id: 'locationID', numeric: true, disablePadding: false, label: t('students.locationID', { ns: 'tableHeadCells' }) },
        { id: 'restrictAccessToLocation', numeric: true, disablePadding: false, label: t('students.restrictAccessToLocation', { ns: 'tableHeadCells' }) },
    ])
    const [tableCourseInGroupCell3] = useState([
        { id: 'ferpA_Item', numeric: true, disablePadding: false, label: t('students.ferpA_Item', { ns: 'tableHeadCells' }) },
        { id: 'allowDisplay', numeric: true, disablePadding: false, label: t('students.allowDisplay', { ns: 'tableHeadCells' }) },
        { id: 'relationCanRecv', numeric: true, disablePadding: false, label: t('students.relationCanRecv', { ns: 'tableHeadCells' }) },
    ])
    const handleClose = () => {
        setPopupData({ ...popupData, open: false })
        setIsloading(false);
        onClose();
    }
    const okAction = () => {
        setPopupData({ ...popupData, open: false })
        setIsloading(false);
    }
    useEffect(() => {
        if (selectedSelected) {
            // setIsloading(true);
            getApi(`${student_alert}/${selectedSelected.studentUID}`).then((resp) => {
                const { academicAlerts, studentAlert, businessAlerts, ferpaWarnings } = resp.data.data;
                if (academicAlerts.length > 0 || businessAlerts.length > 0 || ferpaWarnings.length > 0) {
                    let alertsData = {
                        academicAlerts,
                        studentAlert,
                        businessAlerts,
                        ferpaWarnings
                    }
                    let tableData = [
                        { tableName: `${t('commonLabel.academicAlerts')}`, data: "academicAlerts", headcells: tableCourseInGroupCell1 },
                        { tableName: t('commonLabel.businessAlerts'), data: "businessAlerts", headcells: tableCourseInGroupCell2 },
                        { tableName: t('commonLabel.ferpaWarning'), data: "ferpaWarnings", headcells: tableCourseInGroupCell3 }
                    ]
                    setPopupData({
                        popupHeaderTitle: 'Alert',
                        alertsData: alertsData,
                        open: true,
                        tableData: tableData,
                        studentId: selectedSelected.studentID,
                        studentName: studentAlert[0]["firstName"] ? studentAlert[0]["lastName"] + "," + studentAlert[0]["firstName"] : "",
                        action: okAction
                    })
                } else {
                    setIsloading(false);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [selectedSelected])
    useEffect(() => {
        if (history.location.state) {
            const { selectedRow } = history.location.state
            SetSelectedSelected(history.location.state.selectedRow);
            dispatch(getSelectedProfiles({ profileName: selectedRow.studentName, profileId: selectedRow.studentID, type: "student", studentUID: selectedRow.studentUID }))
        }
    }, [])

    const onClose = (value) => {
        SetSelectedSelected(value);
        dispatch(getSelectedProfiles({}));
        history.push("/financial-aid/student-search")
        addData(false);
        document.getElementsByTagName("html")[0].classList.remove("popup-Page");
    };

    const addData = (value) => {
        setShowTab(value)
    }
    const [selectedStatusRow, setSelectedStatusRow] = useState('');

     useEffect(() => {
       
        if (selectedStatusRow !== "" && history.location.state && history.location.state.selectedRow.studentUID == selectedStatusRow.studentUID) {
            setDisableFlag(false);
        }
        else {
            setDisableFlag(true);
            
        }
    }, [selectedStatusRow])
 

    const tabValue = [
        {
            text: t("tabLabels.status", { ns: "financialAid" }),
            component: <Status addDatas={addData} setStatusFyDropDown={setStatusFyDropDown} onClose={onClose} selectedSelected={selectedSelected} selectedStatusRow={selectedStatusRow} setSelectedStatusRow={setSelectedStatusRow} setFyDropDown={setFyDropDown} />
        },
        {
            text: t("tabLabels.isir", { ns: "financialAid" }),
            component: <Isir
                disableFlag={disableFlag}
                addDatas={addData}
                onClose={onClose}
                selectedSelected={selectedSelected}
                fyDropDown={fyDropDown}
                selectedStatusRow={selectedStatusRow}
            />
        },
        {
            text: t("tabLabels.awards", { ns: "financialAid" }),
            component: <Awards addDatas={addData} onClose={onClose} selectedSelected={selectedSelected}
                selectedStatusRow={selectedStatusRow} setSelectedStatusRow={setSelectedStatusRow}
                fyDropDown={fyStatusDropDown}
                disableFlag={disableFlag}
            />
        },
        {
            text: t("tabLabels.documents", { ns: "financialAid" }),
            component: <Documents disableFlag={disableFlag} addDatas={addData} onClose={onClose} selectedSelected={selectedSelected} setSelectedStatusRow={setSelectedStatusRow} selectedStatusRow={selectedStatusRow} fyDropDown={fyDropDown} />
        },
        {
            text: t("tabLabels.more", { ns: "financialAid" }),
            component: <More addDatas={addData} onClose={onClose} selectedSelected={selectedSelected} />
        },
        {
            text: t("tabLabels.isirHistorical", { ns: "financialAid" }),
            component: <IsirHistorical
                disableFlag={disableFlag}
                addDatas={addData}
                onClose={onClose}
                selectedSelected={selectedSelected}
                fyDropDown={fyDropDown}
                selectedStatusRow={selectedStatusRow}
            />
        },
    ]

    return (
        <>
            {(!showTab) && <>
                <Breadcrumb profileData={{ profileName: selectedSelected.studentName, profileId: selectedSelected.studentID }}>
                    {[...financialAid_maintenance].map(({ name, isLink, path }, index) => (
                        <BreadcrumbLink key={index} name={name} path={path} isLink={isLink} />
                    ))}
                </Breadcrumb>
                <HeaderTitle headerTitle={title} help={true} buttonText={t('studentLabel.findStudent')} plus={false} url="/financial-aid/student-search" /></>}
            {popupData.open && <AlertsModal popupData={popupData} onClose={handleClose} >
                <br></br>
                <ButtonWrapperFilter className='centerButton'>
                    <Button className='BgYellowOrange search' variant="contained" size="large" onClick={okAction} >
                        {t('buttonLabel.ok')}
                    </Button>
                    <Button className="BgLightBlue search" variant="contained" size="large" type="reset" onClick={handleClose}>
                        {t('buttonLabel.cancel')}
                    </Button>
                </ButtonWrapperFilter>

            </AlertsModal>}
            {isLoading ?
                <CustomLoader /> :
                <>
                    <NormalTabs TabLabels={tabValue} variant="fullWidth" showTab={showTab} />
                </>
            }
        </>
    )
}
export default MaintenanceAid;